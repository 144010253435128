import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';

const TextBoxWrapper = styled('div')`
  margin: 2% auto 2%;
  width: 100%;
  .content-html-text {
    width: 100%;
    margin: 0 auto;
    ${contentTextElements} {
      padding-left: ${theme.general.pagePadding.sm};
      padding-right: ${theme.general.pagePadding.sm};
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          img {
            height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  padding: 2rem 0;
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text?.value;
  const textAlign = props.textAlign?.value;
  const textColor = props.textColorCode?.value || props.textColor?.value;
  const background = props.blockColorCode?.value || props.background?.value;
  const maxWidth = props.maxWidth?.value;
  return (
    <div>
      <TextBoxWrapper style={{background:`${background}`}} data-index={index}>
        <ContentHtml
          style={{ maxWidth: maxWidth, color:`${textColor}`}}
          className={`content-html-text ${textAlign}`}
          content={text}
        />
      </TextBoxWrapper>
    </div>
  );
};

export default TextBox;
